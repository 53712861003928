#sidebar {
  position: fixed;
  top: 10px;
  left: 2px;
  font-size: 1.3em;
}

.ring-in-time-group {
  margin-bottom: 1em;

  & span.heavy {
    font-weight: bold;
  }
}

.pane-left {
  padding: 10px 0px 10px 25px;
  height: 100%;
}

.pane-right {
  padding: 10px 10px 10px 10px;
  height: 100%;
}
.pane-full {
  padding: 10px 10px 10px 25px;
  height: 100%;
}

.pane-body {
  margin: 0 0 -9999px 0;
  padding: 0;
  background-color: #ffffff;
  color: #333333;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  height: 100%;
  overflow: auto;
}

.pane-info {
  padding: 0px 10px 10px 10px;
}
