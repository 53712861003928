@use "~layouts/css/variables";

.picklist_item_step_in {
  color: variables.$brand-success;
  cursor: pointer;
}

.picklist_item_format {
  color: variables.$brand-primary;
  cursor: pointer;
}

.picklist_item_halt_range,
.picklist_item_halt_incomplete,
.picklist_item_halt_none {
  color: variables.$brand-danger;
  cursor: auto;
}
