#sidebar {
  position: fixed;
  top: 10px;
  left: 2px;
  font-size: 1.3em;
}

.ring-in-time-group {
  margin-bottom: 1em;
}
.ring-in-time-group span.heavy {
  font-weight: bold;
}

.pane-left {
  padding: 10px 0px 10px 25px;
  height: 100%;
}

.pane-right {
  padding: 10px 10px 10px 10px;
  height: 100%;
}

.pane-full {
  padding: 10px 10px 10px 25px;
  height: 100%;
}

.pane-body {
  margin: 0 0 -9999px 0;
  padding: 0;
  background-color: #ffffff;
  color: #333333;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  height: 100%;
  overflow: auto;
}

.pane-info {
  padding: 0px 10px 10px 10px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9wYWdlcy9jc3MiLCJzb3VyY2VzIjpbImluZGV4LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTs7QUFFQTtFQUNFOzs7QUFJSjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTs7O0FBRUY7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIiNzaWRlYmFyIHtcbiAgcG9zaXRpb246IGZpeGVkO1xuICB0b3A6IDEwcHg7XG4gIGxlZnQ6IDJweDtcbiAgZm9udC1zaXplOiAxLjNlbTtcbn1cblxuLnJpbmctaW4tdGltZS1ncm91cCB7XG4gIG1hcmdpbi1ib3R0b206IDFlbTtcblxuICAmIHNwYW4uaGVhdnkge1xuICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICB9XG59XG5cbi5wYW5lLWxlZnQge1xuICBwYWRkaW5nOiAxMHB4IDBweCAxMHB4IDI1cHg7XG4gIGhlaWdodDogMTAwJTtcbn1cblxuLnBhbmUtcmlnaHQge1xuICBwYWRkaW5nOiAxMHB4IDEwcHggMTBweCAxMHB4O1xuICBoZWlnaHQ6IDEwMCU7XG59XG4ucGFuZS1mdWxsIHtcbiAgcGFkZGluZzogMTBweCAxMHB4IDEwcHggMjVweDtcbiAgaGVpZ2h0OiAxMDAlO1xufVxuXG4ucGFuZS1ib2R5IHtcbiAgbWFyZ2luOiAwIDAgLTk5OTlweCAwO1xuICBwYWRkaW5nOiAwO1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmZmZmO1xuICBjb2xvcjogIzMzMzMzMztcbiAgYm9yZGVyOiAxcHggc29saWQgdHJhbnNwYXJlbnQ7XG4gIGJvcmRlci1yYWRpdXM6IDRweDtcbiAgYm94LXNoYWRvdzogMCAxcHggMXB4IHJnYmEoMCwgMCwgMCwgMC4wNSk7XG4gIGhlaWdodDogMTAwJTtcbiAgb3ZlcmZsb3c6IGF1dG87XG59XG5cbi5wYW5lLWluZm8ge1xuICBwYWRkaW5nOiAwcHggMTBweCAxMHB4IDEwcHg7XG59XG4iXX0= */