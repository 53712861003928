@use "~layouts/css/variables";

ul.affix {
  position: fixed;
}

ul.affix-top {
  position: static;
}

ul.affix-bottom {
  position: absolute;
}

.sidebar .nav > li > a {
  display: block;
  color: #716b7a;
  padding: 5px 14px;
}

.sidebar .nav > li > a:hover,
.sidebar .nav > li > a:focus {
  text-decoration: none;
  padding: 5px 10px;
  border-left: 4px solid variables.$brand-success;
  background-color: transparent;
  color: variables.$brand-success;
}

.sidebar .nav > .active > a,
.sidebar .nav > .active:hover > a,
.sidebar .nav > .active:focus > a {
  font-weight: bold;
  text-decoration: none;
  padding: 5px 10px;
  border-left: 4px solid variables.$brand-primary;
  background-color: transparent;
  color: variables.$brand-primary;
}
