@use "./variables";

// Body styles
html, body, .container-fluid, .row {
  height: 100%;
}

.bg-dark {
  background-color: variables.$gray-darker;
  color: variables.$gray-lighter;
}

.bg-light {
  background-color: variables.$gray-lighter;
  color: variables.$gray-darker;
}

// Bootstrap extensions

.row-skinny {
  margin-right: 0px;
  margin-left: 0px;
}

.row-bottom-space {
  margin-bottom: 5px;
}

.text-italics {
  font-style: italic;
}

.text-bold {
  font-weight: 600;
}

.inline {
  display: inline;
}

.table > thead > tr > th.no-line,
.table > tbody > tr > th.no-line,
.table > tfoot > tr > th.no-line,
.table > thead > tr > td.no-line,
.table > tbody > tr > td.no-line,
.table > tfoot > tr > td.no-line {
  border-top: 0px solid #ffffff;
}

.readonly {
  margin-top: 7px;
}

.chzn-field {
  margin-top: 4px;
}

.select2-results {
  color: black;
}

.calendar-heading {
  display: none;
}

hr.short {
  margin-top: 10px;
  margin-bottom: 10px;
}

.link-inverse {
  color: variables.$brand-success;
}

.margins-all-around {
  margin: 10px;
}

// TODO Temporary
.highlight {
  // border: 1px solid red;
  background-color: yellow;
}

.link {
  cursor: pointer;
}

.table-borderless > tbody > tr > td {
  border: none;
}

.table-short {
  margin-bottom: 0;
}

.link-inverse {
  color: lighten(variables.$brand-success, 10%);
}
