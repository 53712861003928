@use "./variables";
@use "~bootstrap-sass/assets/stylesheets/bootstrap/variables" as bootstrap;

// Theme colors

$callout-primary-text:          variables.$brand-primary;
$callout-primary-border:        variables.$brand-primary-light;
$callout-primary-bg:            variables.$brand-primary-lighter;

$callout-success-text:          variables.$brand-success;
$callout-success-border:        variables.$brand-success-light;
$callout-success-bg:            variables.$brand-success-lighter;

$callout-info-text:             variables.$brand-info;
$callout-info-border:           variables.$brand-info-light;
$callout-info-bg:               variables.$brand-info-lighter;

$callout-warning-text:          variables.$brand-warning;
$callout-warning-border:        variables.$brand-warning-light;
$callout-warning-bg:            variables.$brand-warning-lighter;

$callout-danger-text:           variables.$brand-danger;
$callout-danger-border:         variables.$brand-danger-light;
$callout-danger-bg:             variables.$brand-danger-lighter;

// Basic colors

$callout-red-text:           variables.$brand-red;
$callout-red-border:         variables.$brand-red-light;
$callout-red-bg:             variables.$brand-red-lighter;

$callout-orange-text:        variables.$brand-orange;
$callout-orange-border:      variables.$brand-orange-light;
$callout-orange-bg:          variables.$brand-orange-lighter;

$callout-yellow-text:        variables.$brand-yellow;
$callout-yellow-border:      variables.$brand-yellow-light;
$callout-yellow-bg:          variables.$brand-yellow-lighter;

$callout-green-text:         variables.$brand-green;
$callout-green-border:       variables.$brand-green-light;
$callout-green-bg:           variables.$brand-green-lighter;

$callout-blue-text:          variables.$brand-blue;
$callout-blue-border:        variables.$brand-blue-light;
$callout-blue-bg:            variables.$brand-blue-lighter;

$callout-purple-text:        variables.$brand-purple;
$callout-purple-border:      variables.$brand-purple-light;
$callout-purple-bg:          variables.$brand-purple-lighter;

$callout-pink-text:          variables.$brand-pink;
$callout-pink-border:        variables.$brand-pink-light;
$callout-pink-bg:            variables.$brand-pink-lighter;


// Callout basics
.callout {
  margin-bottom: bootstrap.$line-height-computed;
  padding: 20px;
  border-left: 3px solid variables.$gray-lighter;

  & h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  & p:last-child {
    margin-bottom: 0;
  }
}

.callout-sm {
  padding: 10px;
}

.callout-short {
  margin-bottom: 5px;
}


// Theme colors

.callout-primary {
  background-color: $callout-primary-bg;
  border-color: $callout-primary-border;
  & h4 { color: $callout-primary-text; }
}

.callout-success {
  background-color: $callout-success-bg;
  border-color: $callout-success-border;
  & h4 { color: $callout-success-text; }
}

.callout-danger {
  background-color: $callout-danger-bg;
  border-color: $callout-danger-border;
  & h4 { color: $callout-danger-text; }
}

.callout-warning {
  background-color: $callout-warning-bg;
  border-color: $callout-warning-border;
  & h4 { color: $callout-warning-text; }
}

.callout-info {
  background-color: $callout-info-bg;
  border-color: $callout-info-border;
  & h4 { color: $callout-info-text; }
}


// Basic colors

.callout-red {
  background-color: $callout-red-bg;
  border-color: $callout-red-border;
  & h4 { color: $callout-red-text; }
}

.callout-orange {
  background-color: $callout-orange-bg;
  border-color: $callout-orange-border;
  & h4 { color: $callout-orange-text; }
}

.callout-yellow {
  background-color: $callout-yellow-bg;
  border-color: $callout-yellow-border;
  & h4 { color: $callout-yellow-text; }
}

.callout-green {
  background-color: $callout-green-bg;
  border-color: $callout-green-border;
  & h4 { color: $callout-green-text; }
}

.callout-blue {
  background-color: $callout-blue-bg;
  border-color: $callout-blue-border;
  & h4 { color: $callout-blue-text; }
}

.callout-purple {
  background-color: $callout-purple-bg;
  border-color: $callout-purple-border;
  & h4 { color: $callout-purple-text; }
}

.callout-pink {
  background-color: $callout-pink-bg;
  border-color: $callout-pink-border;
  & h4 { color: $callout-pink-text; }
}
