@use "~layouts/css/variables";

.time-section {
  border: solid 1px variables.$gray-light;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background-color: variables.$gray-lighter;
  }

  &.selected {
    border: solid 1px variables.$brand-primary-light;
    border-radius: 15px;
    background-color: variables.$brand-primary;
    color: #FFFFFF;
  }
}

.time-section-unselectable {
  border: solid 1px variables.$gray-light;
  border-radius: 15px;
}

.time-section-report {
  border: solid 1px variables.$gray-lighter;
  border-radius: 15px;
}

.schedule-div {
  min-height: 125px;
}

.day {
  &.next-month, &.prev-month {
    background-color: variables.$gray-lighter;
  }

  &.past {
    background-color: variables.$gray-lighter;
  }
}

.today {
  &.wday-0 { background-color: variables.$brand-red-lighter; }
  &.wday-1 { background-color: variables.$brand-orange-lighter; }
  &.wday-2 { background-color: variables.$brand-yellow-lighter; }
  &.wday-3 { background-color: variables.$brand-green-lighter; }
  &.wday-4 { background-color: variables.$brand-blue-lighter; }
  &.wday-5 { background-color: variables.$brand-purple-lighter; }
  &.wday-6 { background-color: variables.$brand-pink-lighter; }
}

.v-middle {
  padding-top: 14px;
}
