@use "./variables";
@use "~bootstrap-sass/assets/stylesheets/bootstrap/variables" as bootstrap;

// Theme colors

$header-primary-text:          variables.$brand-primary;
$header-primary-border:        variables.$brand-primary-light;
$header-primary-bg:            variables.$brand-primary-lighter;

$header-success-text:          variables.$brand-success;
$header-success-border:        variables.$brand-success-light;
$header-success-bg:            variables.$brand-success-lighter;

$header-info-text:             variables.$brand-info;
$header-info-border:           variables.$brand-info-light;
$header-info-bg:               variables.$brand-info-lighter;

$header-warning-text:          variables.$brand-warning;
$header-warning-border:        variables.$brand-warning-light;
$header-warning-bg:            variables.$brand-warning-lighter;

$header-danger-text:           variables.$brand-danger;
$header-danger-border:         variables.$brand-danger-light;
$header-danger-bg:             variables.$brand-danger-lighter;

// Basic colors

$header-red-text:           variables.$brand-red;
$header-red-border:         variables.$brand-red-light;
$header-red-bg:             variables.$brand-red-lighter;

$header-orange-text:        variables.$brand-orange;
$header-orange-border:      variables.$brand-orange-light;
$header-orange-bg:          variables.$brand-orange-lighter;

$header-yellow-text:        variables.$brand-yellow;
$header-yellow-border:      variables.$brand-yellow-light;
$header-yellow-bg:          variables.$brand-yellow-lighter;

$header-green-text:         variables.$brand-green;
$header-green-border:       variables.$brand-green-light;
$header-green-bg:           variables.$brand-green-lighter;

$header-blue-text:          variables.$brand-blue;
$header-blue-border:        variables.$brand-blue-light;
$header-blue-bg:            variables.$brand-blue-lighter;

$header-purple-text:        variables.$brand-purple;
$header-purple-border:      variables.$brand-purple-light;
$header-purple-bg:          variables.$brand-purple-lighter;

$header-pink-text:          variables.$brand-pink;
$header-pink-border:        variables.$brand-pink-light;
$header-pink-bg:            variables.$brand-pink-lighter;




/// Header basics
.header {
  margin-bottom: bootstrap.$line-height-computed;
  padding: 10px;
  border-top: 3px solid variables.$gray-lighter;
  border-bottom: 3px solid variables.$gray-lighter;

  & h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  & p:last-child {
    margin-bottom: 0;
  }
}

.header-short {
  margin-bottom: 5px;
}


// Theme colors

.header-primary {
  background-color: $header-primary-bg;
  border-color: $header-primary-border;
  & h4 { color: $header-primary-text; }
}

.header-success {
  background-color: $header-success-bg;
  border-color: $header-success-border;
  & h4 { color: $header-success-text; }
}

.header-danger {
  background-color: $header-danger-bg;
  border-color: $header-danger-border;
  & h4 { color: $header-danger-text; }
}

.header-warning {
  background-color: $header-warning-bg;
  border-color: $header-warning-border;
  & h4 { color: $header-warning-text; }
}

.header-info {
  background-color: $header-info-bg;
  border-color: $header-info-border;
  & h4 { color: $header-info-text; }
}


// Basic colors

.header-red {
  background-color: $header-red-bg;
  border-color: $header-red-border;
  & h4 { color: $header-red-text; }
}

.header-orange {
  background-color: $header-orange-bg;
  border-color: $header-orange-border;
  & h4 { color: $header-orange-text; }
}

.header-yellow {
  background-color: $header-yellow-bg;
  border-color: $header-yellow-border;
  & h4 { color: $header-yellow-text; }
}

.header-green {
  background-color: $header-green-bg;
  border-color: $header-green-border;
  & h4 { color: $header-green-text; }
}

.header-blue {
  background-color: $header-blue-bg;
  border-color: $header-blue-border;
  & h4 { color: $header-blue-text; }
}

.header-purple {
  background-color: $header-purple-bg;
  border-color: $header-purple-border;
  & h4 { color: $header-purple-text; }
}

.header-pink {
  background-color: $header-pink-bg;
  border-color: $header-pink-border;
  & h4 { color: $header-pink-text; }
}
